module stratos.common.models {
    class userUI {
        constructor();
        constructor(
            public DisplayDarkTheme?: boolean,
            public HasAcceptedEula?: boolean,
            public HasAcceptedInternetExplorerMessage?: boolean,
            public HideIntroTour?: boolean,
            public HighSpeedUploading?: boolean,
            public RememberMe?: boolean
        ){
        }
    }

    export class user extends userUI {

        constructor();
        constructor(
            public AadUserId?: string,
            public UserId?: string,
            public UserName?: string,
            public AadUserName?: string,
            public GivenName?: string,
            public Surname?: string,
            public AvailableTenants?: tenant[],
            public UiActiveTenant?: tenant,
            public VisualisorServerUrl?: string,
            public MaxAcceptedEulaVersion?: number
        ) {
            super();
        }        
    }
}